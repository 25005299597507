/* Database Page */

.database {
  margin: 4rem auto 1rem auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;
}

/* theme style */

.ag-theme-alpine {
  --ag-grid-size: 5px;

  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: black;
  --ag-header-background-color: rgb(170, 170, 170);

  --ag-cell-horizontal-border: solid lightgray;
}

.ag-header-cell-label {
  justify-content: center;
}

/* table, buttons, and forms */

.job-table {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  height: 84vh;
  width: 95vw;
  max-width: 1200px;
  border: 1px rgb(175, 175, 175) solid;
  box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.4);
  background-color: rgb(175, 175, 175);

  overflow: hidden;
}

.data-form {
  height: 100%;
}

.button-list {
  margin: -.75rem auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  justify-self: flex-end;
  align-self: flex-end;
  width: 95%;
  max-width: 1200px;
  min-height: 1.5rem;
  
  /* hack to put buttons slightly over top of table headers */
  position: relative;
  z-index: 3;
}

.rig-database .button-list {
  width: 100%;
}

.user-database .button-list {
  width: 100%;
}

.button-list button {
  background-color:rgb(224, 194, 22);
  color: rgb(48, 48, 48);
  border: none;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
  margin: .25rem;
  padding: .25rem;
}

.button-list button:hover {
  background-color: rgb(48, 48, 48);
  color:rgb(224, 194, 22);
}

.active {
  display: block;
}

.custom-filter {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.filter-option {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
}

input[type='radio'] {
  margin: 0 4px 0 0;
}