* {
    box-sizing: border-box;
}

html {
    touch-action: manipulation;
}
  
body {
    background-image: linear-gradient(120deg, rgb(124, 124, 124), rgb(132, 132, 132), rgb(150, 150, 150), rgb(170, 170, 170));
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    min-width: 100vw;
    min-height: 100vh;
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(48, 48, 48);

}
  
#root {
    width: 100vw;
    margin: 0;
    padding: 0;
    display: inline-block;

    /* prevents whole page scrolling database, calendar, map pages */
    overflow: hidden;
} 





/* @media only screen and (max-width: 740px) { 
       
} */