.mapview-page {
  margin: 4rem auto;
  position: relative;
}

.mapview-button-list {
  margin: -.75rem auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 95%;
  min-height: 1.5rem;
  
  /* hack to put buttons slightly over top of table headers */
  position: relative;
  z-index: 1000;
}

.mapview-button-list button {
  background-color:rgb(224, 194, 22);
  color: rgb(48, 48, 48);
  border: none;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
  margin: .25rem;
  padding: .25rem;
}

.mapview-button-list button:hover {
  background-color: rgb(48, 48, 48);
  color:rgb(224, 194, 22);
}

.strata-mapper-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  margin: 0rem auto;
  width: 95vw;
  height: 80vh;
}

.strata-mapper {
  max-width: 1080px;
  height: 100%;
  width: 100%;
}

.strata-mapper-tool {
  width: 275px;
  height: 100%;
  margin-left: .5rem;
}

.popUp-window {
  width: fit-content;
}

.popper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 8rem;
  
  /* border: 1px red solid; */
}

.popper-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1rem;
  font-weight: bold;

  /* border: 1px green solid; */
}

.popper button {
  font-size: .75rem;
  background-color:rgb(224, 194, 22);
  color: rgb(48, 48, 48);
  border: none;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
  padding: .25rem;
}

.popper button:hover {
  background-color: rgb(48, 48, 48);
  color:rgb(224, 194, 22);
}

.popper-info {

  /* border: 1px blue solid; */
}

.mapview-page .form-container {
  width: 275px;
}