.calendar-page {
    margin: 3.5rem .5rem 3rem .5rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.calendar-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 5px;
  
  position: relative;
}

/* display for the current month/year and buttons for changing the month */

.month-selector {
  background-color: rgb(170, 170, 170);
  width: 45vw;
  min-width: 300px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  margin: .25rem;
  padding: .5rem;
  box-shadow: 2px 1px 7px 1px rgba(0, 0, 0, 0.4);
}

.month-arrow {
  font-size: 2rem;
  background-color:rgb(224, 194, 22);
  color: rgb(48, 48, 48);
  border: none;
  box-shadow: 2px 5px 5px -3px rgb(0,0,0, 0.4);
  padding: auto;
}

.month-arrow:hover {
  background-color: rgb(48, 48, 48);
  color:rgb(224, 194, 22);
}

.current-monthYear {
  font-size: 2rem;
  text-shadow: 2px 1px 3px rgba(100, 100, 100, 0.75);

}

/* Unassigned jobs label and buttons to control the unassigned joblist and form */

.calendar-form-controller {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  padding: 0px;
  margin: 0 .5rem -.75rem 0;
}

.calendar-form-button {
  background-color:rgb(224, 194, 22);
  color: rgb(48, 48, 48);
  border: none;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
  padding: .25rem;
}

.calendar-form-button:hover {
  background-color: rgb(48, 48, 48);
  color:rgb(224, 194, 22);
}

.rig-detail-job button {
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
}

/* styles for the unassigned joblist, which is in the same place as the job form */

.unassigned-joblist {
  width: 20vw;
  height: 90vh;
  min-width: 15rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  background-color: rgb(205, 205, 205);
  border: 1px rgb(175, 175, 175) solid;
  padding: .75rem 5px;

  overflow: scroll;
}

.unassigned-job {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  font-size: 14px;

  background-color: white;
  margin-bottom: 2px;
  border: 1px rgb(170, 170, 170) solid;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.75);
}

.unassigned-job > button {
  margin-left: 1px;
}

.unassigned-job > div {
  border-right: 1px solid rgb(150,150,150);
  flex: 1;
}

.unassigned-job > div:last-of-type {
  border-right: none;
}


/* wrappers for the calendar and form together, the calendar and day labels together, and the calender form itself */
.calendar-container {
  display: flex;
  flex-flow: row nowrap;
}

.calendar-form {
  box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.4);
}

.calendar {
  background-color: rgb(150, 150, 150);
  box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.4);
}

/* use a flexbox for the names of the days of the week, to keep it separate totally from the code for generating the data for the grid */

.day-of-week {
  border: 2px black solid;
  border-bottom: none;
  background-color: rgb(140, 140, 140);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.dayName {
  border-right: 2px black solid;
  flex: 1;
  text-align: center;
  padding: 5px 0px 2px 0px;
}

.dayName:last-child {
  border-right: none;
}

/* actual month grid */
.month-grid {
  width: 100%;
  min-height: 70vh;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  grid-auto-rows: 1fr;
  align-items: stretch;
  border: 1px rgb(175, 175, 175) solid;
  border: 1px black solid;
}

.day {
  background-color: rgb(205, 205, 205);
  border: 1px black solid;
}

.day-label {
  padding-left: 5px;
}

.day-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

/* styles for the individual rig on the calendar for each day */

.day-rig {
  position: relative;
  font-size: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 2px;
  padding: 5px;
  min-width: 50px;
  box-shadow: 2px 1px 6px -3px rgb(50, 50, 50);

}

.day-rig-id {
  text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.75);
  align-self: flex-start;
  margin-left: 5px;
  position: absolute;
  left: 0;
}

.day-rig-count {
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-items: center;

  background-color: white;
  width: 1rem;
  height: 1.5rem;
  border-radius: 5px;
  padding: 2px;
  margin: 0px 3px;

  /* font-size: 16px;
  color: black;
  text-align: center; */

  border: 1px darkgray solid;
}

.counter-bar {
  width: 100%;
  height: 30%;
  background-color: gray;
  margin-top: 2px;
}

.two-length {
  height: 70%;
}

.full-length {
  height: 100%;
  background-color: rgb(156, 6, 6);
}

.counter-bar:last-child {
  margin-top: 0;
}

.day-rig-detail {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  
  border-radius: 5px;
  min-height: 2rem;
  width: 12rem;
  z-index: 15;
}

.rig-info {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px black solid;
  padding: 2px 3px;
  background-color: rgb(140, 140, 140);
  color: white;
}


.rig-detail-job {
  padding: 2px 5px;
  border-bottom: 1px solid rgb(150,150,150);
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.rig-detail-job:last-child {
  border-bottom: none;
  border-radius: 0px 0px 5px 5px;
}

.rig-icons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: red;
  /* margin-top: -5px; */
  /* margin-right: -5px; */
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
}
