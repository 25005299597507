/* Footer */

footer {
    height: 5vh;
    min-height: 35px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(120deg, rgb(158, 158, 158), rgb(180, 180, 180));
    width: 100vw;
    border-top: 3px rgb(224, 194, 22) solid;

    /* some of the admin buttons were floating over the footer because of the hack I needed to add to get them floating over the grids properly */
    z-index: 15;
}

.copyright-username {
    display: flex;
    justify-content: center;
    align-items: center;
    color:rgb(224, 194, 22);
    font-size: .8rem;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
}

.big-vert-line {
    font-weight: normal;
    font-size: 1.5rem;
    border: 1px rgb(224, 194, 22) solid;
    box-shadow: 1px 1px 2px black;
    margin: 0rem .5rem;
    height: 70%;
    width: 1px;
    padding: 0;
}

footer p {
    padding-left: .5rem;
    padding-right: .5rem;
}

footer p span {
    color: rgb(48, 48, 48);
    text-shadow: none;
    padding-left: .5rem;
}

footer button {
    background-color:rgb(224, 194, 22);
    color: rgb(48, 48, 48);
    height: 80%;
    border: none;
    box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
    margin-left: 1rem;
}

footer button:hover {
    background-color: rgb(48, 48, 48);
    color:rgb(224, 194, 22);

}

@media only screen and (max-width: 740px) {

    .copyright-username {
        font-size: .6rem;
    }
    
    .big-vert-line {
        font-weight: normal;
        font-size: 1.2rem;
        margin-top: 0rem;
        margin-bottom: auto;
    }
    
    footer p {
        padding-left: 0;
        padding-right: 0;
    }

}