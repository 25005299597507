.form-container {
  font-size: 12px;
  width: 20vw;
  height: 100%;
  background-color: rgb(205, 205, 205);
}

.admin-form {
  max-width: 300px;
}

.form-container button {
  background-color:rgb(224, 194, 22);
  color: rgb(48, 48, 48);
  border: none;
  box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
  margin: 0 .25rem;
  padding: .25rem;
}

.form-container button:hover {
  background-color: rgb(48, 48, 48);
  color:rgb(224, 194, 22);
}

.job-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
}

.job-form .input-section {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin-bottom: .5rem;
  border: 1px rgb(170, 170, 170) solid;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.75);
}

.job-form .checkbox {
  display: flex;
  flex-flow: row nowrap;
}

.job-form .input-section input, select {
  float: right;
  padding: .5rem;
  width: 60%;
  height: 100%;
  min-width: 50px;
  border: none;
}

.job-form .input-label {
  background-color: rgb(170,170,170);
  padding: .5rem;
  width: 40%;
  height: 100%;
  text-align: center;
}