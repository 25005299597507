.admin-tools {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
}

.tool {
  width: 80vw;
  max-width: 800px;
  margin-bottom: 1rem;
}

.admin-table {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  height: 375px;
  border: 1px rgb(175, 175, 175) solid;
  box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.4);
  background-color: rgb(175, 175, 175);

  overflow: hidden;
}