/* Login Form */

.login {
    min-height: 87vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.login-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    max-width: 450px;
    box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
}

.login-header {
    text-align: center;
    padding: 1rem 1.5rem .5rem 1.5rem;
    width: 100%;
    margin: 0 -1rem 0 -1rem;
    background-image: linear-gradient(to top right, rgba(224, 194, 22, 1), rgba(224, 194, 22, 0.95), rgba(224, 194, 22, 0.8), rgba(224, 194, 22, 0.5));
}

.login-body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(120deg, rgba(183, 183, 183, 0.6), rgba(188, 188, 188, 0.7), rgba(183, 183, 183, 0.5), rgba(183, 183, 183, 0.4), rgba(178, 178, 178, 0.3));

    width: 100%;
    height: 35vh;
}

.input-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-bottom: .5rem;
}

.input-section input {
    float: right;
    padding: .5rem;
    width: 65%;
    min-width: 150px;
}

.input-label {
    padding: .5rem;
}

.login-button {
    padding: 1rem 2rem;
    margin-top: 1rem;

    background-color:rgb(224, 194, 22);
    color: rgb(48, 48, 48);
    border: none;
    box-shadow: 1px 1px 8px -3px rgb(0,0,0, 0.4);
}

.login-button:hover {
    background-color: rgb(48, 48, 48);
    color:rgb(224, 194, 22);
}

/* @media only screen and (max-width: 740px) { 
       
} */